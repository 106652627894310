<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Checkout Pages</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import checkoutPages from '@/services/checkoutPages'
import actionsWrapper from '@/utils/BaseActionsWrapper'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'companyCheckoutPage',
  ['view-checkout-pages', 'edit-checkout-pages', 'delete'],
  'checkout-pages'
)

const columns = [
  {
    _t_id: 'b2cc608c',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '9a4b0d9e',
    prop: 'name',
    text: 'Name',
    sort: true,
    type: 'text',
    sortProp: 'name',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'a24ef27e',
    prop: 'domain',
    text: 'Domain',
    sort: true,
    type: 'text',
    sortProp: 'domain',
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Checkout Pages',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        enableStatusFilterToggle: false,
        enableExport: false,
        detailKeyId: 'companyCheckoutPageId',
        tableId: 'checkout_pages_tv_view',
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'checkout-pages.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns,
        collection: 'CheckoutPages',
        action: (params) => checkoutPages.getCheckoutPages(params),
      },
    }
  },
}
</script>
